import React from "react";
import MultiplePizzas from "../assets/masala.jpg";
import "../styles/About.css";
function About() {
  return (
    <div className="about">
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${MultiplePizzas})` }}
      ></div>
      <div className="aboutBottom">
        <h1> ABOUT US</h1>
        <div className="container">
          <p className="centered-text">
            We are dedicated to bringing the vibrant flavors of Hong Kong street
            food to your table. <br />
            All our dishes are homemade with the highest quality ingredients,
            ensuring an authentic
            <br /> taste that satisfies the homesickness of our friends and
            community. Our menu features a<br /> variety of Hong Kong favorites,
            each crafted with love and tradition. We take pride in offering{" "}
            <br /> food that not only delights your taste buds but also brings
            comfort and a sense of home. <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default About;
