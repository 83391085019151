import Chole from "../assets/chhola.jpg";
import Dosa from "../assets/dosa.jpg";
import Paneer from "../assets/paneer.jpg";
import Idli from "../assets/idli.jpg";
import Gujrati from "../assets/gujrati.jpeg";
import Rajasthani from "../assets/rajasthani.jpg";
const { v4: uuidv4 } = require("uuid");

export const MenuList = [
  {
    name: "Egg waffle",
    image: Chole,
    price: 4.5,
    id: 1,
    quantity: 0,
  },
  {
    name: "Siu Mai",
    image: Dosa,
    price: 7.5,
    id: 2,
    quantity: 0,
  },
  {
    name: "Egg Tart",
    image: Paneer,
    price: 5.5,
    id: 3,
    quantity: 0,
  },
  {
    name: "Tofu pudding",
    image: Idli,
    price: 6.0,
    id: 4,
    quantity: 0,
  },
  {
    name: "Cheung Fun",
    image: Gujrati,
    price: 6.5,
    id: 5,
    quantity: 0,
  },
  {
    name: "Fishball",
    image: Rajasthani,
    price: 5.0,
    id: 6,
    quantity: 0,
  },
];
