import React from "react";
import PizzaLeft from "../assets/fooda.jpeg";
import "../styles/Contact.css";
import { useHistory } from "react-router-dom";

function Contact() {
  const history = useHistory();
  function box(e) {
    e.preventDefault();

    alert("Thanks for contacting us");
    history.push("/");
  }
  return (
    <div className="contact">
      <div
        className="leftSide"
        style={{ backgroundImage: `url(${PizzaLeft})` }}
      ></div>
      <div className="rightSide">
        <h1> Contact Us</h1>

        <form id="contact-form" onSubmit={box}>
          <label htmlFor="name">Full Name</label>
          <input
            name="name"
            placeholder="Enter full name..."
            type="text"
            required
          />
          <label htmlFor="email">Email</label>
          <input
            name="email"
            placeholder="Enter email..."
            type="email"
            required
          />
          <label htmlFor="message">Message</label>
          <textarea
            rows="6"
            placeholder="Enter message..."
            name="message"
            required
          ></textarea>

          <button> Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
