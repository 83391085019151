import React, { useState, useEffect } from "react";
import { MenuList } from "../helpers/MenuList";
import MenuItem from "../components/MenuItem";
import "../styles/Menu.css";
const { v4: uuidv4 } = require("uuid");

function Menu() {
  const [cart, setCart] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const LOCAL_STORAGE_KEY = "ITEMS";

  useEffect(() => {
    const data = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (data !== null) setCart(JSON.parse(data));
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(cart));
  }, [cart]);

  //let sum = 0;

  let cart_quan = cart.reduce(
    (accumulator, currentValue) =>
      parseInt(currentValue.quantity) + accumulator,
    0
  );

  // const addToCart = (menuItem) => {
  //   cart.forEach((item) => {
  //     if (item.name === menuItem.name) {
  //       sum++;
  //     }
  //   });

  //   if (sum === 0) {
  //     setCart((prevCart) => [
  //       ...prevCart,
  //       { ...menuItem, id: uuidv4(), quantity: 1 },
  //     ]);
  //   }

  //   if (sum > 0) {
  //     sum = 0;
  //     return;
  //   }
  // };

  const add = (menuItem) => {
    const onCart = cart.find((cartItem) => cartItem.name === menuItem.name);

    if (onCart !== undefined) {
      setCart((prevCart) => {
        let newArr = prevCart.map(
          (cartItem) => (
            console.log(cartItem.id, menuItem.id),
            cartItem.id === menuItem.id
              ? { ...cartItem, quantity: cartItem.quantity + 1 }
              : cartItem
          )
        );

        return newArr;
      });
    } else {
      setCart((prevCart) => [...prevCart, { ...menuItem, quantity: 1 }]);
    }
  };

  function remove(cartItem) {
    setCart((prevCart) => {
      let newArr = prevCart.filter((item) => item.id !== cartItem.id);
      return newArr;
    });
  }

  function update(input, item) {
    if (parseInt(input) === 0) {
      setCart((prevCart) => {
        let newArr = prevCart.filter((cartItem) => cartItem.id !== item.id);
        return newArr;
      });
    }
    if (parseInt(input) > 0) {
      setCart((prevCart) => {
        let newArr = prevCart.map((cartItem) =>
          cartItem.id === item.id
            ? { ...cartItem, quantity: parseInt(input) }
            : cartItem
        );
        return newArr;
      });
    }
  }

  function show() {
    setIsOpen((isOpen) => !isOpen);
  }

  function hide() {
    setIsOpen(false);
  }

  function reset() {
    setCart([]);
  }
  return (
    <div className="menu">
      <h2 className="menuTitle">Our Menu</h2>

      <button
        style={{
          width: "3rem",
          height: "3.8rem",
          position: "fixed",
          top: "0px",
          right: "0px",
        }}
        variant="outline-primary"
        className="cartButton"
        onClick={show}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          fill="black"
        >
          <path d="M96 0C107.5 0 117.4 8.19 119.6 19.51L121.1 32H541.8C562.1 32 578.3 52.25 572.6 72.66L518.6 264.7C514.7 278.5 502.1 288 487.8 288H170.7L179.9 336H488C501.3 336 512 346.7 512 360C512 373.3 501.3 384 488 384H159.1C148.5 384 138.6 375.8 136.4 364.5L76.14 48H24C10.75 48 0 37.25 0 24C0 10.75 10.75 0 24 0H96zM128 464C128 437.5 149.5 416 176 416C202.5 416 224 437.5 224 464C224 490.5 202.5 512 176 512C149.5 512 128 490.5 128 464zM512 464C512 490.5 490.5 512 464 512C437.5 512 416 490.5 416 464C416 437.5 437.5 416 464 416C490.5 416 512 437.5 512 464z" />
        </svg>
        <div
          style={{
            borderRadius: "50%",
            fontWeight: 800,
            background: "black",
            color: "orange",
            width: "1.5rem",
            height: "1.3rem",
            position: "absolute",
            bottom: 0,
            right: 0,
            transform: "translate(25%, 25%)",
          }}
        >
          {cart_quan}
        </div>
      </button>
      <div className="menuList">
        {MenuList.map((menuItem, key) => {
          return (
            <MenuItem
              key={key}
              image={menuItem.image}
              name={menuItem.name}
              price={menuItem.price}
              addToCart={() => add(menuItem)}
            />
          );
        })}
      </div>

      {console.log("cart", cart)}

      {isOpen ? (
        <div className="cart">
          <h2>Basket</h2>

          {cart.length === 0 ? (
            <p>Your cart is empty</p>
          ) : (
            <ul>
              {cart.map((item, index) => (
                <li key={item.id} style={{ fontSize: "14px" }}>
                  <img src={item.image} width="45" height="25" />
                  <input
                    min="0"
                    value={item.quantity}
                    type="number"
                    style={{ width: "25px" }}
                    onChange={(e) => {
                      update(e.target.value, item);
                    }}
                  ></input>
                  {item.name} £{item.price}
                  <button
                    onClick={() => {
                      remove(item);
                    }}
                  >
                    x
                  </button>
                </li>
              ))}
            </ul>
          )}
          <h3>
            Total:£
            {console.log(cart)}
            {cart.reduce(
              (accumulator, currentValue) =>
                currentValue.price * currentValue.quantity + accumulator,
              0
            )}
          </h3>
          <button onClick={reset}>Reset</button>

          <button onClick={hide}>Close</button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Menu;
