import React from "react";

import "../styles/Thanks.css";
function Thanks() {
  console.log("thanks");
  return (
    <div className="thanks">
      <p>hi</p>
    </div>
  );
}

export default Thanks;
