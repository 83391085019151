import React from "react";
import "../styles/Menuitem.css";
function MenuItem({ image, name, price, addToCart }) {
  return (
    <div className="menuItem">
      <div style={{ backgroundImage: `url(${image})` }}></div>
      <h3>{name}</h3>
      <p>£{price}</p>
      <button onClick={addToCart}>Add to Cart</button>
    </div>
  );
}

export default MenuItem;
